.loginLink {
  min-width: 160px;
}

.formHidden {
  height: 230px;
  overflow: hidden;
  position: relative;
}

.formHidden:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.87) 0%,
    rgba(250, 250, 250, 0.85) 50%,
    rgba(255, 251, 240, 1) 100%
  );
  z-index: 999;
}

.formHidden.formOpen {
  height: auto;
  overflow: initial;
}

.formHidden.formOpen:before {
  display: none;
}
