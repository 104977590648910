.productThumbnail {
  max-width: 80px;
}
.product {
  position: relative;
  width: 100%;
  padding-left: 10px;
  font-size: 14px;
}
.productBorder {
  border-bottom: 1px dashed #c8c8c8;
  &:last-child {
    border-bottom: 1px solid #c8c8c8;
  }
}

.discountTag {
  display: inline-block;
  vertical-align: baseline;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
  margin-right: 5px;
  margin-left: 5px;
  color: #ff6478;
  border: 1px solid #ff6478;
}
.discountTag:first-child {
  margin-left: 0;
}

.normalBox {
  border: 2px solid #fac83c;
  padding: 0 20px 20px;
  border-radius: 5px;
  overflow: hidden;
}

.normalTitle {
  background-color: #fac83c;
  margin-left: -20px;
  margin-right: -20px;
  padding: 10px 20px;
}

.freezeBox {
  border: 2px solid #8cb4dc;
  padding: 0 20px 20px;
  border-radius: 5px;
  overflow: hidden;
}

.freezeTitle {
  background-color: #8cb4dc;
  margin-left: -20px;
  margin-right: -20px;
  padding: 10px 20px;
}

.flex {
  display: flex;
}
.flexCenter {
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}
.flexRight {
  margin-left: auto;
}

.selector {
  width: 65px;
}

.cartError {
  background-color: #00508c;
  border-radius: 4px;
  padding: 15px 10px;
}

.totalPriceTable {
  display: table;
  width: 100%;
  dt,
  dd {
    display: table-cell;
    vertical-align: text-bottom;
  }
}

.productAnnotation {
  white-space: pre-wrap;
}
