.section {
  margin-bottom: 32px;
}

.subText {
  margin-left: 5px;
}

.titleContainer {
  justify-content: center;
}
