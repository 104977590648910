.product {
  border-bottom: 1px dashed #c8c8c8;
}

.imageContainer {
  width: 90px;
}

.image {
  width: 100%;
  max-width: 80px;
}

.table {
  display: table;
  width: 100%;
}

.cell {
  display: table-cell;
  vertical-align: top;
}
