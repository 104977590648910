.modalContainer {
  position: relative;
  width: 476px;
  height: 80vh;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  @media screen and (max-width: 768px) {
    border-radius: 0;
    height: 100vh;
    height: -webkit-fill-available;
    height: 100dvh;
    width: 100vw;
    margin: 0;
    overflow: scroll;
  }
}

.modalBody {
  position: relative;
  padding: 110px 28px 92px 28px;
  height: 100%;
  overflow: scroll;
}

.modalPrice {
  background-color: #f0f0f0;
  padding: 10px 28px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  @media screen and (max-width: 768px) {
    position: fixed;
  }
}
.modalPriceWrap {
  max-width: 476px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.borderTop {
  border-top: 1px solid #c8c8c8;
}
.text__flex__left {
  margin-right: auto;
}
.deliverableContainer {
  max-width: 476px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 16px;
  height: 16px;
  position: relative;
}
.deliverableProgressbar {
  position: absolute;
  left: 0;
  top: 0;
  height: 16px;
  border-radius: 16px;
  background-color: #fac83c;
  width: 90%;
}
.deliverableProgressbarTag {
  position: absolute;
  left: 10px;
  top: -1px;
}
.deliverableProgressPrice {
  position: absolute;
  right: 0;
  padding: 0 10px;
  line-height: 16px;
}
.modalFooter {
  display: block;
  padding: 10px 28px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fac83c;
  @media screen and (max-width: 768px) {
    position: fixed;
  }
}
.buttonWrapper {
  max-width: 476px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonCancel {
  color: #5a5a5a;
  font-size: 16px;
  line-height: 48px;
  letter-spacing: 0.03em;
}
.buttonSave {
  font-size: 16px;
  line-height: 48px;
  letter-spacing: 0.03em;
}
