.default {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: transparent;
  border: 1px solid #c8c8c8;
  outline: none;
  border-radius: 4px;
  font-size: 16px;
  padding: 0 10px;
  color: #5a5a5a;
}

.default:focus {
  border: 2px solid #f3c959;
  outline: none;
}
.default.error {
  border: 2px solid #ee6e7b;
  background-color: rgba(243, 201, 89, 0.2);
}
.default:disabled {
  background-color: rgba(204, 204, 204, 0.2);
}
