.nameRow {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1036px) {
    flex-direction: column;
  }
}

.sectionAmazonpay {
  border: 1px solid #fac83c;
  padding: 30px 20px;
  border-radius: 13px;
}
.tagAmazon {
  display: inline-block;
  vertical-align: middle;
  background-color: #fac83c;
  padding: 2px 7px;
  border-radius: 5px;
  margin-right: 5px;
}
.titleBorder {
  border-bottom: 1px solid #c8c8c8;
}

.checkboxInput:checked + label:after {
  top: 0;
  bottom: 0;
  margin: auto;
}
.radioInput + label {
  display: block;
}
.subtitle {
  margin-left: 5px;
}
.paymentImage {
  position: absolute;
  right: 0;
  top: 0;
}
.monthYear {
  position: relative;
}
.monthYear:after {
  content: "/";
  position: absolute;
  right: 0;
  top: 0;
  line-height: 50px;
}
.normal {
  height: 230px;
  overflow: hidden;
  position: relative;
}
.normal:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.87) 0%,
    rgba(250, 250, 250, 0.85) 50%,
    rgba(255, 251, 240, 1) 100%
  );
  z-index: 999;
}
.normal.open {
  height: auto;
  overflow: auto;
}
.normal.open:before {
  display: none;
}
.processBar {
  position: relative;
  background-color: #f0f0f0;
  padding-left: 20px;
  padding-right: 20px;
}
.processBar span {
  position: relative;
  z-index: 5;
  color: #5a5a5a;
}
.processBar b {
  position: relative;
  z-index: 5;
  border-bottom: 1px solid;
  color: #ff6478;
  margin: 0 3px;
}
.processBg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #fac83c;
  z-index: 1;
}
.processBtn {
  display: block;
  width: 100%;
  background-color: #ff6478;
}
.hideForm {
  z-index: 1000;
  position: relative;
  margin-top: -190px;
  margin-bottom: 220px;
}
@keyframes fadein {
  0% {
    bottom: -100px;
    opacity: 0;
  }
  20% {
    bottom: 28px;
    opacity: 1;
  }
  80% {
    bottom: 28px;
    opacity: 1;
  }
  100% {
    bottom: -100px;
    opacity: 0;
  }
}
@media screen and (max-width: 767px) {
  .subtitle {
    margin-left: 0;
    display: block;
  }
  .paymentImage {
    position: relative;
    display: block;
    margin-top: 10px;
  }
}
.modalDefaultButton {
  display: inline-block;
  margin: 0.5em 0.4em 0.5em 0;
  padding: 0.4em 1em;
  border: 1px solid #c5c5c5;
  border-radius: 3px;
  background: #f6f6f6;
  cursor: pointer;
  color: #454545;
  font-size: 16px;
  &:hover {
    border: 1px solid #cccccc;
    background: #ededed;
    color: #2b2b2b;
  }
}
