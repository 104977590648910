.itemContainer {
  padding: 10px 0px 20px;
}

.itemRow {
  margin: 16px 0;
}

.selector {
  width: 100%;
}

.dateSelectorsContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 28px; // アイテム間の隙間

  @media (min-width: 1036px) {
    grid-template-columns: 1fr 1fr;
  }
}

.deliveryTypeTag {
  margin-bottom: 10px !important;
  span:first-child {
    margin-left: 0;
  }
}
