.container {
  max-width: 476px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 16px;
  height: 16px;
  position: relative;
}

.progressbar {
  position: absolute;
  left: 0;
  top: 0;
  height: 16px;
  border-radius: 16px;
  width: 90%;
}

.normalColor {
  background-color: #fac83c;
}

.freezeColor {
  background-color: #8cb4dc;
}

.progressbarTag {
  position: absolute;
  left: 10px;
  top: -1px;
}

.progressPrice {
  position: absolute;
  right: 0;
  padding: 0 10px;
  line-height: 16px;
}
