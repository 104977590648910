.normal {
  border: 2px solid #fac83c;
  padding: 0 20px 20px;
  border-radius: 5px;
  overflow: hidden;
}

.title {
  margin-left: -20px;
  margin-right: -20px;
  padding: 10px 20px;
}

.normalColor {
  background-color: #fac83c;
}

.freeze {
  border: 2px solid #8cb4dc;
  padding: 0 20px 20px;
  border-radius: 5px;
  overflow: hidden;
}

.freezeColor {
  background-color: #8cb4dc;
}
