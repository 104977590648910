.itemContainer {
  padding: 10px 0px 20px;
}

.itemRow {
  margin: 16px 0;
}

.deliveryOptionSelector {
  width: 100%;
  height: 50px;
}

.selector {
  width: 100%;
  height: 50px;
}

.dateSelectorsContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 28px; // アイテム間の隙間

  @media (min-width: 1036px) {
    grid-template-columns: 1fr 1fr;
  }
}

.questionIcon {
  margin: 0px 4px;
  display: flex;
  align-items: center;
}

.imageLabelContainer {
  justify-content: space-between;
  align-items: center;
}

.payment__image {
  position: absolute;
  right: 0;
  top: 0;
  width: 167px;
  height: 24px;
}

@media screen and (max-width: 767px) {
  .payment__image {
    position: relative;
    display: block;
    margin-top: 10px;
  }
}

.creditCardFormContainer {
  margin-top: 20px;
  margin-bottom: 40px;
}

.bankTransferFormContainer {
  margin-top: 0px;
  margin-bottom: 32px;
}

.konbiniContainer {
  margin: 32px 24px;
}
