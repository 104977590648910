.table {
  display: table;
  width: 100%;
}

.cell {
  display: table-cell;
  vertical-align: top;
}

.temperatureTagFirst {
  margin-left: 0 !important; // tagのスタイル上書き
}
